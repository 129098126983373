/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import * as React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Polityka prywatności | Fair Fur</title>
    <meta name="description" content="" />
    <link rel="canonical" href="https://fairfur.pl" />
  </>
);

const Main = styled.main`
  margin-bottom: 0;
  padding: 100px 0;
`;

const Privacy = styled.section`
  max-width: 800px;
  margin: 0 auto;

  h1 {
    text-align: center;
  }

  a {
    color: #78569d;
  }

  .list-privacy {
    list-style: none;
    padding: 0;

    > li {
      margin: 0 0 75px;
    }

    ul {
      list-style: disc;
      margin: 0 0 2rem;
    }
  }

  .policy-heading {
    display: flex;
    flex-wrap: wrap;

    h2, p {
      text-align: center;
      width: 100%;
    }

    h2 {
      font-size: 2.6rem;
      order: 1;
    }

    p {
      font-size: 1.8rem;
      margin: 0 0 4px;
    }
  }
`;

const PrivacyPolicy = () => (
  <Main>
    <Privacy className="row">
      <h1>Polityka prywatności</h1>

      <ul className="list-privacy">
        <li>
          <div className="policy-heading">
            <h2>Postanowienia ogólne</h2>
            <p>§1</p>
          </div>

          <p>
            Niniejszy dokument określa Politykę Prywatności strony
            {' '}
            <a href="/">https://fairfur.pl</a>
            , która w szczególności obejmuje regulacje dotyczące ochrony danych osobowych oraz bezpieczeństwa innych danych wprowadzonych do Serwisu przez Użytkownika.
          </p>

          <p>Polityka Prywatności stanowi integralny załącznik do Regulaminu.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Definicje</h2>
            <p>§2</p>
          </div>

          <p>Terminy użyte w niniejszym dokumencie oznaczają:</p>
          <p>
            Administrator – Femi Trade Sp. z o.o.,
            <br />
            KRS: 0000893840,
            <br />
            NIP: 7272849256,
            <br />
            REGON: 388646260,
            <br />
            SĄD REJONOWY DLA M.ST. WARSZAWY W WARSZAWIE, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO
          </p>

          <p className="no-marg">
            <strong>Serwis</strong>
            {' '}
            - strona internetowa pod adresem http://fairfur.pl oraz wszelkie jej podstrony,
          </p>
          <p className="no-marg">
            <strong>Strony</strong>
            {' '}
            - Administrator i Użytkownik
          </p>
          <p className="no-marg">
            <strong>Użytkownik</strong>
            {' '}
            - osoba fizyczna, która korzysta z Serwisu i podaje w jego ramach swoje dane osobowe.
          </p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Ochrona danych osobowych</h2>
            <p>§3</p>
          </div>

          <p>
            Administrator jest Administratorem danych osobowych w rozumieniu rozporządzenia o
            ochronie danych osobowych z dnia 27 kwietnia 2016 r. (RODO).
          </p>

          <p>
            Administrator przetwarza dane w zakresie, czasie i celach każdorazowo wskazanych w
            treściach udostępnionych pod formularzami służącymi do zbierania danych osobowych od
            Użytkownika.
          </p>

          <p>
            Dane osobowe będą przekazywane wyłącznie zaufanym podwykonawcom Administratora, tj.
            dostawcom usług informatycznych, firmie księgowej, administracji.
          </p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Uprawnienia użytkownika</h2>
            <p>§4</p>
          </div>

          <p>W przypadku zmiany danych osobowych, Użytkownik powinien uaktualnić je wysyłając stosowną wiadomość do Administratora.</p>
          <p>Użytkownikowi przysługuje prawo żądania dostępu do treści swoich danych osobowych, ich sprostowania, usunięcia oraz prawo do ograniczenia ich przetwarzania. Ponadto także prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych.</p>
          <p>Użytkownikowi przysługuje prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</p>
          <p>Podanie danych osobowych jest dobrowolne, jednakże brak podania danych uniemożliwia korzystanie z Serwisu.</p>
          <p>Administrator może odmówić usunięcia danych osobowych Użytkownika, jeżeli zachowanie danych osobowych jest konieczne ze względu na obowiązek nałożony na Administratora przez przepisy prawa.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Techniczna ochrona danych</h2>
            <p>§5</p>
          </div>

          <p>Administrator wykorzystuje wszelkie techniczne i organizacyjne sposoby, by zapewnić bezpieczeństwo danym osobowym Użytkownika i chronić je przed przypadkowym lub celowym zniszczeniem, przypadkową utratą, modyfikacją, nieautoryzowanym ujawnieniem lub dostępem. Informacje są przechowywane i przetwarzane na serwerach o wysokim stopniu zabezpieczeń, z zachowaniem odpowiednich środków bezpieczeństwa, spełniających wymogi polskiego prawa.</p>
          <p>Administrator zobowiązuje się do przechowywania kopii bezpieczeństwa zawierających dane osobowe Użytkownika.</p>
          <p>Powierzone dane są składowane na najwyższej klasy sprzęcie i serwerach w odpowiednio zabezpieczonych centrach przechowywania informacji, do których dostęp mają tylko upoważnione osoby.</p>
          <p>Administrator przeprowadza czynności związane z przetwarzaniem danych osobowych z poszanowaniem wszystkich wymogów prawnych i technicznych nałożonych na niego przez przepisy dotyczące ochrony danych osobowych.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Polityka cookies</h2>
            <p>§6</p>
          </div>

          <p>Dla wygody Użytkowników Serwis używa plików cookies m.in. w celu dostosowania serwisu do potrzeb użytkowników oraz w celach statystycznych. Cookies to niewielkie pliki tekstowe wysyłane przez serwis internetowy, który odwiedza internauta, do urządzenia internauty</p>
          <p>W ramach Serwisu stosowane są dwa typy plików cookies: "sesyjne" (session cookies) oraz "stałe" (persistent cookies). Cookies "sesyjne" są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). Stałe" pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</p>
          <p>W Serwisie wykorzystujemy następujące rodzaje plików Cookies:</p>

          <ul>
            <li>
              <strong>"niezbędne"</strong>
              {' '}
              - umożliwiają korzystanie z usług dostępnych w ramach Serwisu, np. wykorzystywane przy obsłudze autoryzacji użytkowników,
            </li>
            <li>
              <strong>"zabezpieczające"</strong>
              {' '}
              - służą do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie korzystania z usług Serwisu,
            </li>
            <li>
              <strong>"wydajnościowe"</strong>
              {' '}
              - umożliwiają zbieranie informacji o sposobie korzystania z Serwisu,
            </li>
            <li>
              <strong>"funkcjonalne"</strong>
              {' '}
              - umożliwiają zapamiętanie wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu Serwisu itp.,
            </li>
            <li>
              <strong>"reklamowe"</strong>
              {' '}
              - umożliwiają dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań,
            </li>
            <li>
              <strong>"integracyjne"</strong>
              {' '}
              - związane są z wykorzystywanymi w Serwisie usługami firm trzecich, np. Google Analytics, tawk.to.
            </li>
          </ul>

          <p>Serwisy obce, których materiały przedstawiamy, mogą także używać cookies, które umożliwiają logowanie się, oraz służą dostarczaniu reklam odpowiadających upodobaniom i zachowaniom użytkownika.</p>
          <p>W przeglądarce internetowej można zmienić ustawienia dotyczące cookies. Brak zmiany tych ustawień oznacza akceptację dla stosowanych tu cookies.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Logi</h2>
            <p>§7</p>
          </div>

          <p>Zgodnie z praktyką większości serwisów www przechowujemy zapytania HTTP kierowane do naszego serwera (logi serwera). W związku z powyższym przechowujemy:</p>

          <ul>
            <li>adresy IP, z których użytkownicy przeglądają treści informacyjne naszego serwisu;</li>
            <li>czas nadejścia zapytania,</li>
            <li>czas wysłania odpowiedzi,</li>
            <li>nazwę stacji klienta - identyfikacja realizowana przez protokół HTTP,</li>
            <li>informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP,</li>
            <li>adres URL strony poprzednio odwiedzanej przez użytkownika (referer link),</li>
            <li>informacje o przeglądarce użytkownika.</li>
          </ul>

          <p>Dane gromadzone w dziennikach logów, dane są wykorzystywane tylko i wyłącznie do celów administrowania Serwisem.</p>
          <p>Zebrane logi przechowywane są przez czas nieokreślony jako materiał pomocniczy służący do administrowania Serwisem. Informacje w nich zawarte nie są ujawniane nikomu poza osobami upoważnionymi do administrowania Serwisem. Na podstawie plików logów mogą być generowane statystyki stanowiące pomoc w administrowaniu. Zbiorcze podsumowania w postaci takich statystyk nie zawierają żadnych cech identyfikujących osoby odwiedzające serwis.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Kontakt</h2>
            <p>§8</p>
          </div>
          <p>
            Użytkownik może w dowolnym czasie skontaktować się z Administratorem w celu uzyskania
            informacji o tym, czy i w jaki sposób Administrator wykorzystuje jego dane osobowe.
          </p>
          <p>
            Użytkownik może również zwrócić się do Administratora z prośbą o usunięcie jego danych
            osobowych w całości lub w określonej części.
          </p>
          <p>
            Z Administratorem można skontaktować się wysyłając wiadomość na adres e-mail:
            {' '}
            <a href="mailto:infofemi-trade.com">info@femi-trade.com</a>
          </p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Newsletter</h2>
            <p>§9</p>
          </div>

          <p>Przetwarzamy Twoje dane osobowe wskazane w formularzu zapisu do newslettera.</p>
          <p>Administratorem danych osobowych jest:</p>
          <p>
            Femi Trade Sp. z o.o.,
            <br />
            KRS: 0000893840,
            <br />
            NIP: 7272849256,
            <br />
            REGON: 388646260,
            <br />
            SĄD REJONOWY DLA M.ST. WARSZAWY W WARSZAWIE, XIII WYDZIAŁ GOSPODARCZY
            KRAJOWEGO REJESTRU SĄDOWEGO,
            <br />
            mail:
            {' '}
            <a href="mailto:info@femi-trade.com">info@femi-trade.com</a>
          </p>

          <p>Dane osobowe będą przetwarzane w celu marketingu bezpośredniego (art. 6 ust. 1 lit. f RODO) Administratora. Dane osobowe będą przechowywane przez okres do dnia wypisania się z newslettera lub złożenia sprzeciwu wobec przetwarzania w ten sposób. Podanie danych osobowych jest dobrowolne, jednakże brak ich podania uniemożliwia otrzymywanie wiadomości od nas. Przysługuje Ci prawo żądania dostępu do treści danych osobowych, ich sprostowania, usunięcia oraz prawo do ograniczenia ich przetwarzania. Ponadto także prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych, Posiadasz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Dane osobowe będą przekazywane wyłącznie naszym zaufanym podwykonawcom, tj. dostawcom usług informatycznych.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Polityka cookies</h2>
            <p>§10</p>
          </div>

          <p>Przetwarzamy Twoje dane osobowe wskazane w formularzu powyżej. Administratorem danych osobowych jest:</p>

          <p>
            Femi Trade Sp. z o.o.,
            <br />
            KRS: 0000893840,
            <br />
            NIP: 7272849256,
            <br />
            REGON: 388646260,
            <br />
            SĄD REJONOWY DLA M.ST. WARSZAWY W WARSZAWIE, XIII WYDZIAŁ GOSPODARCZY
            KRAJOWEGO REJESTRU SĄDOWEGO,
            <br />
            mail:
            {' '}
            <a href="mailto:info@femi-trade.com">info@femi-trade.com</a>
          </p>

          <p>Dane osobowe będą przetwarzane w celu przeprowadzenia czynności przed zawarciem umowy, na Twoje żądanie, w celu zawarcia i realizacji umowy o świadczenie usług (art. 6 ust. 1 lit. b RODO). Dane osobowe przetwarzane w celu przeprowadzenia czynności przed zawarciem umowy, zawarcia i realizacji umowy o świadczenie usług drogą elektroniczną będą przetwarzane do zakończenia umowy o świadczenie usług z Administratorem oraz czas niezbędny do wywiązania się z obowiązków nałożonych przez przepisy prawa (rozliczenia, przedawnienia roszczeń). Podanie danych osobowych jest dobrowolne, jednakże brak ich podania uniemożliwia otrzymywanie wiadomości od nas. Przysługuje Ci prawo żądania dostępu do treści danych osobowych, ich sprostowania, usunięcia oraz prawo do ograniczenia ich przetwarzania. Ponadto także prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych, Posiadasz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Dane osobowe będą przekazywane wyłącznie naszym zaufanym podwykonawcom, tj. dostawcom usług informatycznych.</p>
        </li>
      </ul>
    </Privacy>
  </Main>
);

export const query = graphql`
  query {
    hero: file(relativePath: {regex: "/home\/hero.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
  }`;

export default PrivacyPolicy;
